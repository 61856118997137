.popup{
    padding: 12px 16px;
    box-shadow: 0 4px 14px 0 #0000000F;
    border-radius: 98px;
    background-color: white;
    opacity: 1;
    z-index: 10;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: -40px;
    left: calc(50% - 103px);
    transition-duration: 300ms;
}
.popup.showed{
    bottom: 98px;
}
.popup p{
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #0F213FCC;
    margin-left: 8px;

}