#footer{
    width: 100%;
    min-height: 96px;
    background-color: #5056C9;
    padding-top: 28px;
    padding-bottom: 28px;
    color: white!important;
}
#footer .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#footer a{
    color: white;
}
#footer .contact{
    display: flex;
    align-items: center;
}
#footer .contact:last-child{
    margin-top: 15px;
}
#footer .contact a{
    font-family: 'Gilroy';
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.03em;
    text-align: left;
    margin-left: 14.5px;
}
#footer .buttons{
    display: flex;
    justify-content: space-between;
    width: 630px;
    align-items: center;
}
#footer .buttons a{

    font-family: 'Gilroy';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.03em;
    text-align: right;
    opacity: 0.5;

}
#footer a{
    transition-duration: 300ms;
}
#footer a:hover{
    opacity: 1;
    text-decoration: underline;
}


.landing-contacts__col{
    display: flex;
    flex-direction: column;

}
@media (max-width: 1025px) {
    .landing-contacts__col{
        margin-right: auto;
        margin-top: 20px;
    }
}
.landing-contacts__p{
    margin-bottom: 20px;
    color: white;
}

.landing-contacts__p1{
    max-width: 305px;
    color: white;
}
.landing-contacts__p2{
    max-width: 330px;
    color: white;
}
.landing-contacts__p3{
    width: 282px;
    margin-left: auto;
    text-align: right;
    color: white;
}
@media (max-width: 1025px) {
    .landing-contacts__p3{
        margin-left: 0;
        text-align: left;
    }
}
.landing-contacts__p4{
    width: 282px;
    margin-left: auto;
    text-align: right;

}
@media (max-width: 1025px) {
    .landing-contacts__p4{
        margin-left: 0;
        text-align: left;
    }
}
.landing-contacts__row{
    display: flex;
    align-items: center;
}
.landing-contacts__row1{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.landing-contacts__row3 a{
    transition-duration: 300ms;
    opacity: 0.3;
    margin-left: 20px;
}
.landing-contacts__row3 a:hover{
    opacity: 1;
    text-decoration: underline;
}
@media (max-width: 1025px) {
    .landing-contacts_row3{
        margin-left: 0;
        text-align: left;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}
.landing-contacts__p3{
    margin-top: 36px;
}
.landing-contacts__p4{
    margin-top: 1rem;
    color: white;
}
.landing-contact__p4 a{
    transition-duration: 300ms;
    color: white;
}
.landing-contact__p4 a:hover{
    color: white;
    opacity: 1!important;
    text-decoration: underline;
}

.landing-contacts__img1{
    margin-right: 15px;
}
.landing-contacts__img3{
    margin-right: 18px;
}
.landing-contacts__footer {
    display: flex;
    align-items: flex-end;
    padding: 4rem 0;
}
@media (max-width: 1024px) {
    .landing-contacts__footer{
        flex-flow: column;
        align-items: center;
    }
}
.landing-contacts__footer p, .landing-contacts__footer a{
    opacity: 0.5;
    color: #fff;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.03em;
}
.landing-contacts__footer p:first-child{
    margin-right: auto;
}

.landing-contacts__footer p:first-child {
    margin-right: auto;
}
@media (max-width: 1024px) {
    .landing-contacts__footer p:first-child{
        margin-right: initial;
    }
}
