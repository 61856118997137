.testCreation{
    width: 100%;
    min-height: calc(100vh - 88px);
    background-color: #F4F7F9;
    padding-bottom: 100px;
}
.testCreationHolder{
    min-height: 100%;
    height: 100%;
}
.testCreation .container{
    padding-top: 16px;
    max-width: 1020px;
}
.creationBar{
    box-shadow: 0px 4px 14px 0px #0000000F;
    height: 68px;
    background-color: white;
}
.creationBarButton{
    width: 340px;
    border: 0;
    height: 100%;
    background-color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;

}
.creationBarButton.active{
    border-bottom: 4px solid #5056C9;
    height: calc(100% - 4px);
}
.checker{
    width: 30px;
    height: 20px;
    position: relative;
    cursor: pointer;
}
.checker input{
    width: 30px;
    height: 20px;
    position: absolute;
    z-index: 2;
    opacity: 0;
}
.checker input:checked ~ .line{
    background-color: rgba(75, 190, 65, 0.34);
}
.checker input:checked ~ .roundCheck{
    background-color: #4BBE41;
    left: 11px;
}

.checker .line{
    width: 30px;
    height: 6px;
    position: absolute;
    top: 7px;
    left:1px;
    background-color: rgba(156, 171, 180, 0.5);
    border-radius: 3px;
    z-index: 0;
    transition-duration: 300ms;
}
.checker .roundCheck{
    background-color: #9CABB4;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left:0;
    border-radius: 50%;
    z-index: 1;
    transition-duration: 300ms;
}
.creationContent{
    margin-top: 16px;
}
.creationContent .row{
    column-gap: 30px;
}
.leftMenu{
    width: 285px;
}
.leftMenu .checkBlock{
    background-color: white;
    padding: 20px 24px;
}

.leftMenu .checkBlock h4{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 4px;
}
.leftMenu .checkBlock p{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #122443;
    margin-bottom: 20px;
    opacity: 0.5;

}
.leftMenu .checkBlock:first-child{
    border-bottom: 1px solid #D3DADE;
}
.leftMenu > button{
    margin-top: 8px;
    width: 100%;
    height: 48px;
    border: 0;
    background-color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-align: center;
    color: #5056C9;
    text-transform: uppercase;
}
.creationContent .innerCreation{
    background-color: white;
    padding: 40px 40px 48px 40px;
    width: 625px;
}
.dropdownInput{
    position: relative;
    width: 100%;
    min-height: 50px;
}
.dropdownInput label{
    position: absolute;
    left: 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    top: 18px;
    color: #122443;
    opacity: 0.4;
    z-index: 1;
    transition-duration: 200ms;

}
.dropdownInput input{
    width: 100%;
    border: 0;
    border-bottom: 1px solid #D3DADE;
    outline: none!important;
    color: #122443;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    position: absolute;
    top: 0px;
    height: 50px;
    transition-duration: 200ms;

}
.dropdownTags .content{
    height: 625px;
    overflow: scroll;
}
.dropdownInput input:focus ~ label, .dropdownInput input:not(:placeholder-shown) ~ label{
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    top: 0px;
}
.dropdownInput.error input{
    border-bottom: 2px solid #EB3349;
}
.dropdownInput .infoDot{
    display: none;
    position: absolute;
    top: 16px;
    right: 0;
}
.dropdownInput .infoBlock{
    overflow: hidden;
    width: 0;
    height: 0;

    position: absolute;
    left: 100%;
    background-color: #0F213FCC;
    border-radius: 6px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    opacity: 0;
    transition-duration: 300ms;
    transition: opacity;
    color: white;
    z-index: 10;
}
.dropdownInput.error .infoDot{
    display: block;
    cursor: pointer;
}
.dropdownInput.error .basicInfo{
    display: none;

}
.dropdownInput.error .infoDot:hover ~ .infoBlock{
    opacity: 1;
    width: max-content;
    height: max-content;
}
.dropdownTextfield{
    position: relative;
    width: 100%;
    margin-top: 32px;
}
.dropdownTextfield label{
    position: absolute;
    left: 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    top: 18px;
    color: #122443;
    opacity: 0.4;
    z-index: 1;
    transition-duration: 200ms;

}
.dropdownTextfield textarea{
    width: 100%;
    border: 0;
    border-bottom: 1px solid #D3DADE;
    outline: none!important;
    color: #122443;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;

    top: 0px;
    height: 50px;
    transition-duration: 200ms;

}
.dropdownTextfield textarea:focus, .dropdownTextfield textarea:not(:placeholder-shown){
    height: 82px;
    margin-top: 10px;
}
.dropdownTextfield textarea:focus{
    border-bottom: 2px solid #5056C9;

}
.dropdownTextfield textarea:focus ~ label, .dropdownTextfield textarea:not(:placeholder-shown) ~ label{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    top: -10px;
}
.creationContent .rightMenu{
    width: 705px;
    position: relative;
}
.scalesPage{
    margin-bottom: 16px;
}
.publishTest{
    border: 8px solid white;
    background-color: #22C114;
    color: white;
    width: 100%;
    height: 64px;
    text-transform: uppercase;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-align: center;
    margin-top: 50px;

}
.addScaleButton .plusButton{

    background-color: #5056C91F;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition-duration: 300ms;


}
.addScaleButton{
    width: calc(100% - 48px);
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.51px solid #D3DADE;
    padding: 24px;
    margin-bottom: 8px;
    cursor: pointer;
}
.addScaleButton h4{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 4px;

}
.addScaleButton p{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #122443;
    opacity: 0.5;
}
.addScaleButton:hover{
    background-color: white;
    border-color: white;
}

.scaleSettings{
    width: calc(100% - 80px);
    background-color: white;
    padding: 24px 40px 40px 40px;
    margin-bottom: 4px;
}
.scaleContent{
    background-color: white;
    padding: 24px 40px;
    margin-bottom: 2px;
}
.levelDropdown{
    background-color: #F4F7F9;
    width: 221px;
    height: 20px;
    display: flex;
    align-items: center;

    padding: 15px 12px;
    border-radius: 25px;
}
.levelDropdown .color{
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50%;

}
.levelDropdown .color img{
    width: 20px;
    height: 20px;
}
.levelDropdown > p{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    width: 157px;
    margin-right: 16px;

}
.scaleContent .dropdownInput{
    width: 160px;
}
.scaleContent .dropdownInput input{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;

}
.scaleContent .dropdownInput input:focus ~ label, .dropdownInput input:not(:placeholder-shown) ~ label{
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;


}
.scaleContent .dropdownInput input{
    padding-bottom: 12px;
    padding-top: 18px;
    height: 20px;
}
.scaleSettings > .dropdownInput{
    height: 64px;
}
.question > .dropdownInput input:focus, .question > .dropdownInput input:not(:placeholder-shown), .scaleSettings > .dropdownInput input:focus, .scaleSettings > .dropdownInput input:not(:placeholder-shown){

    height: 32px;
    padding-top: 22px;
    padding-bottom: 10px;
}
.scaleSettings > .dropdownInput input{
    padding: 0;
    height: 50px;
}
.tools{
    width: calc(100% - 28px);
    background-color: white;
    display: flex;
    justify-content: flex-end;
    padding-right: 28px;
    padding-top: 16px;
    padding-bottom: 16px;
    align-items: center;
    position: relative;
}
.addNewScaleButton{
    background-color: #5056C9;
    box-shadow: 0px 6px 12px 0px #5056C933;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -50px;
    left: calc(50% - 28px);
}
.addNewScaleButton img{
    filter: brightness(0) invert(1);
    width: 20px;
    height: 20px;
}
.addNewScaleButtonDropdown{
    display: none;
    width: 495px;
    position: absolute;
    top: -194px;
    z-index: 10;
    box-shadow: 4px 6px 12px 0px #9CABB424;
}
.addNewScaleButtonDropdown.opened{
    display: block;
}
.addNewScaleButtonDropdown .button{
    background-color: white;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.addNewScaleButtonDropdown .button:first-child{
    margin-bottom: 2px;
}
.addNewScaleButtonDropdown .button p{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 4px;
}
.addNewScaleButtonDropdown .button label{
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #122443;

    opacity: 0.5;

}

.addNewScaleButtonDropdown .plus img{
    filter: none;
    width: 14px;
    height: 14px;
}
.addNewScaleButtonDropdown .plus{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #5056C91F;
    display: flex;
    align-items: center;
    justify-content: center;
}
.addNewScaleButtonDropdown .button:hover{
    background-color: #5056c9;
    transition-duration: 300ms;
}
.addNewScaleButtonDropdown .button:hover p{
    color: white;
}
.addNewScaleButtonDropdown .button:hover label{
    color: white;
    opacity: 0.7;
}
.addNewScaleButtonDropdown .button:hover .plus{
    background-color: #FFFFFF1F;
}
.addNewScaleButtonDropdown .button:hover img{
    filter: brightness(0) invert(1);
}
.question{
    padding: 8px 40px 24px;
    background-color: white;
}
.question > img{
    margin-left: calc(50% - 4px)
}

.question h3{
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0.02em;
    text-align: left;
    padding-bottom: 12px;
    border-bottom: 1px solid #D3DADE;

}
.question .inputs{
    padding-top: 24px;
}
.question .inputs .row{
    justify-content: space-between;
}
.question .inputs .row:first-child{
    margin-bottom: 16px;
}
.question .inputs .row .questionDropdown{
    padding: 14px 16px;
}
.question .inputs .row .questionDropdown:first-child{
    width: 322px;
}
.question .inputs .row:first-child .questionDropdown:last-child p{
    width: 164px;
}
.question .inputs .row .questionDropdown:last-child p{
    color: #0F213F;
    width: 185px;
    opacity: 0.4;
}
.question .inputs .row .questionDropdown p{
    width: 298px;
    margin-right: 8px;
}
.question .inputs .row .questionDropdown.questionChecker{
    background-color: white;
    border: 1px solid #EBEEF0;
    width: 320px;
}
.questionChecker p{
    width: 264px;
}
.pointsCounter{
    background-color: white;
    width: 35px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;


}
.addAnswer{
    margin-top: 4px;
}
.addAnswer .inner {
    background-color: white;
    padding: 24px 40px;
    border-bottom: 1px solid #D3DADE;
    display: flex;
    align-items: center;
}
.addAnswer .inner .row{
    align-items: center;
    justify-content: flex-start;
    column-gap: 8px;
    cursor: pointer;
}
.addAnswer .inner .row p{
    color: #5056C9;
    text-transform: uppercase;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.08em;
    text-align: left;

}
.addAnswer .inner .plusButton{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #5056C9;
    display: flex;
    align-items: center;
    justify-content: center;
}
.addAnswer .inner .plusButton img{
    filter: brightness(0) invert(1);
    width: 12px;
    height: 12px;
}
.questions .leftMenu{
    background-color: white;
    padding: 12px;
}
.search{
    display: flex;
    width: calc(100% - 27px);
    background-color: #F8F8F8;
    border: 1px solid #EBEEF0;
    border-radius: 24px;
    height: 20px;
    align-items: center;
    padding: 8px 12px;
}
.search input{
    background-color: transparent;
    border: 0;
    border-radius: 0;
    outline: none!important;
    margin-left: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
}
.questionShort{
    width: calc(100% - 24px);
    height: 79px;
    padding: 20px 24px;

    margin-left: -12px;
    border-bottom: 1px solid #D3DADE;

}
.questionShort.active{
    border-left: 3px solid #5056c9!important;
    padding-left: 21px;
}
.questionShort:hover{
    border-left: 3px solid #CED0FF;
    padding-left: 21px;
    transition-duration: 300ms;
}
.questionShort label{
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(15, 33, 63, 0.4);
    margin-bottom: 8px;
}
.questionShort p{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 15px;

}
.questionShort .chips{
    background-color: rgba(80, 86, 201, 0.12);
    width: 115px;
    height: 22px;
    border-radius: 27px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #5056C9;
    display: flex;
    align-items: center;
    justify-content: center;

}
.levelDropdown{
    position: relative;
    transition-duration: 300ms;
    cursor: pointer;
}
.levelDropdown .levelDropdownContent{
    position: absolute;
    display: none;
    width: 205px;
    top: 48px;
    right: 0;
    padding: 16px 20px 20px 20px;
    background-color: white;
    box-shadow: 0px 4px 14px 0px #0000001A;
    z-index: 10;
    max-height: 350px;
    overflow: scroll;
}
.levelDropdown .levelDropdownContent .row{
    margin-top: 12px;
    column-gap: 4px;
}
.levelDropdown:hover{
    background-color: white;
    border-radius: 0;
    border-bottom: 2px solid #5056C9;
    box-shadow: 4px 8px 16px 0px #9CABB424;
    height: 18px;


}
.levelDropdown:hover .levelDropdownContent{
    display: block;
}
.levelDropdownContent .color{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 4px solid white;
    margin: 0;
}
.levelDropdownContent .color:hover{
    border-color: #E2E8EC;
}
.scales .leftMenu .levelDropdown{
    justify-content: space-between;
}
.scales .leftMenu .levelDropdown:last-child{
    margin-top: 12px;
}
.scales .leftMenu  .levelDropdown p{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0;
    color: #0F213FCC;
    opacity: 1;


}
.tools .copyButton, .tools .deleteButton{
    transition-duration: 300ms;
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
}
.tools .copyButton p, .tools .deleteButton p{
    margin-left: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    display: none;
    transition-duration: 500ms;

}
.tools .copyButton:hover p, .tools .deleteButton:hover p{
    display: block;

}
.tools .copyButton:hover, .tools .deleteButton:hover{
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 16px;

    border-radius: 24px;
}
.tools .copyButton:hover{
    margin-right: 20px;
    background-color: #F4F7F9;
}
.tools .deleteButton:hover{
    background: rgba(235, 51, 73, 0.08);
}
.tools .copyButton{
    margin-right: 20px;

}
.tools .deleteButton p{
    color: #EB3349;

}
.addAnswer .answer{
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: white;

}
.addAnswer .answer .dropdownInput:first-child{
    width: 455px;
}
.addAnswer .answer.slider .dropdownInput:first-child{
    width: 245px;
}
.addAnswer .answer .dropdownInput:last-child{
    width: 140px;
}
.addAnswer .answer.slider .dropdownInput:last-child{
    width: 350px;
}
.addAnswer .answer:first-child{
    margin-top: 4px;
}
.addAnswer .answer input{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    padding-top: 18px;
    padding-bottom: 12px;
    height: 20px;
}
.addAnswer .answer input:focus ~ label, .addAnswer .answer input:not(:placeholder-shown) ~ label{
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    top: 0;

}
.question .inputs .levelDropdownContent.onlyText, .checkBlock .levelDropdownContent.onlyText{
    padding: 0;
    width: 100%;
}
.question .inputs .levelDropdownContent.onlyText p, .checkBlock .levelDropdownContent.onlyText p{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    padding: 8px 16px;
    margin: 0;
    width: calc(100% - 32px);
}
.question .inputs .levelDropdownContent.onlyText p:hover, .checkBlock .levelDropdownContent.onlyText p:hover{
    background-color: #F4F7F9;
}
.question .inputs .levelDropdownContent.answerType .row{
    align-items: center;
    gap: 0;
    margin: 0;
    padding: 8px 16px;
}
.question .inputs .levelDropdownContent.answerType .row:hover{
    background-color: #F4F7F9;
}
.question .inputs .levelDropdownContent.answerType{
    padding: 0;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
}
.question .inputs .questionDropdown .levelDropdownContent.answerType p{

    margin-left: 8px;
    color: #0F213F;
    opacity: 1;

}
.questionShort .row{
    align-items: center;
    gap: 8px;
    margin-left: -8px;
}
.questionShort .colors{
    display: flex;
}
.questionShort .colors .colorDot{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid white;

}
.questionShort .colors .colorDot:first-child{
    margin: 0;
}
.questionShort .colors .colorDot{
    margin-left: -8px;
}
.colorDropdown{
    display: none;
    position: absolute;
    top: -300px;
    z-index: 20;
    background-color: white;
    width: 238px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.sketch-picker {


    width: 238px!important;
    border-radius: 0!important;
    border: 0!important;
    box-shadow: none!important;
    padding: 0!important;
}
.colorDropdown.opened{
    display: block;
}
.levelDropdown .colorDropdown > img{
    margin-left: calc(100% - 26px);
    width: 12px;
    height: 12px;
    padding-top: 14px;
    padding-bottom: 10px;

}
.sketch-picker .flexbox-fix:nth-child(2) > :first-child > :last-child,
.sketch-picker .flexbox-fix:nth-child(2) > :last-child,.sketch-picker .flexbox-fix:nth-child(3) label,
.sketch-picker .flexbox-fix:nth-child(3) > :nth-child(2),  .sketch-picker .flexbox-fix:nth-child(3) > :nth-child(3),
.sketch-picker .flexbox-fix:nth-child(3) > :nth-child(4),  .sketch-picker .flexbox-fix:nth-child(3) > :nth-child(5),
.sketch-picker .flexbox-fix:last-child{
    display: none!important;
}
.sketch-picker .flexbox-fix:nth-child(2){
    padding-top: 16px;
    padding-bottom: 16px;
    margin-left: 12px;
    margin-right: 12px;
}
.sketch-picker .flexbox-fix:nth-child(2) > :first-child{
    padding: 0!important;
}
.sketch-picker .flexbox-fix:nth-child(2) > :first-child > :first-child{
    overflow: visible!important;
}
.sketch-picker .flexbox-fix:nth-child(2) > :first-child > :first-child > :first-child > :first-child > :last-child > :first-child{
    width: 12px!important;
    height: 12px!important;
    margin-top: -3px!important;
    background-color: transparent!important;
    border: 2px solid white!important;
    outline: 0.3px solid #C0C0C0;
    border-radius: 50% !important;
    box-shadow: none!important;
}
.sketch-picker .hue-horizontal{
    border-radius: 5px;
}
.sketch-picker .flexbox-fix:nth-child(3) input{
    padding: 8px 12px!important;
    font-family: 'Gilroy', sans-serif!important;
    font-size: 16px!important;
    font-weight: 400!important;
    line-height: 22px!important;
    letter-spacing: 0em!important;
    text-align: left!important;
    margin-left: 12px!important;
    width: 190px!important;
    border: 1px solid #00000033!important;
    box-shadow: none!important;
    margin-bottom: 12px;

}
.colorDropdown .savedColors{
    padding: 12px 12px 24px 12px;
    border-top: 1px solid #0000001A
}
.colorDropdown .savedColors p{
    font-family: 'Gilroy', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 10px;

}

.colorDropdown .savedColors div{
    display: flex;
}
.question .inputs .row .questionDropdown:last-child .type_text{
    opacity: 1;
}
.question .inputs .row .questionDropdown:last-child .type_text .row{
    justify-content: flex-start;
    column-gap:  8px;
    margin-bottom: 0;
}
.question .inputs .row .questionDropdown:last-child .type_text .row p{
    opacity: 1;
}
.addNewQuestionButton{
    margin-top: 32px;
    bottom: auto;
}
.question_holder{
    margin-top: 12px;
}
.question_holder:first-child{
    margin-top: 0;
}
.levelDropdown.questionDropdown.no-hover{
    background-color: #F4F7F9;
    border-radius: 25px;
    border-bottom: none;
    box-shadow: none;
    height: 20px;
}
.levelDropdown.questionDropdown.no-hover-white{
    background-color: white;
    border-radius: 25px;
    border-bottom: none;
    box-shadow: none;
    height: 20px;
}

.creationBarButton span{
    background-color: #5056C9;
    color: white;
    font-family: 'Gilroy', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding: 2px 4px;
    border-radius: 10px;
    margin-left: 9px;
}
.slider{
    height: 95px;
    background-color: white;
    padding-left: 38.5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D3DADE;
    margin-top: -2px;

}
.slider .line{
    width: 628px;
    height: 2px;
    background-color: #CED0FF;

}
.slider .dots{
    width: 628px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-items: center;


}
.slider .dot{
    width: 10px;
    height: 10px;
    background-color: #CED0FF;
    border-radius: 50%;
    border: 1.5px solid white;
    position: relative;

}
.slider .dot span{
    display: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;

}
.slider .dot.active{
    width: 16px;
    height: 16px;
    background-color: #5056c9;
}
.slider .dot.active::after{
    content: " ";
    width: 6px;
    height: 6px;
    background-color: white;
    top: 5px;
    left: 5px;
    border-radius: 50%;
    position: absolute;
    }
.slider .dot.active span{
    display: block;
    position: absolute;
    top: -19px;
    left: 5px;
}
.dropdownTags{
    margin-top: 32px;
}
.dropdownTags .content{
    position: absolute;
    top: 51px;
    width: 625px;
    max-height: 220px;
    height: 0;
    opacity: 0;
    transition: height;
    transition-duration: 500ms;

}
.dropdownTags .content p{
    padding: 8px 14px 8px 16px;
    background-color: white;
    box-shadow: 4px 8px 16px 0px rgba(156, 171, 180, 0.14);
    cursor: pointer;
}
.dropdownTags .content p:hover{
    background: rgba(244, 247, 249, 1);

}
.dropdownTags input:focus ~ .content{
    opacity: 1;
    height: 220px;

}
.dropdownTags .info{
    position: absolute;
    right: 0;
    top: 16px;
}
.dropdownTags .chips{
    padding-top: 63px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
}
.dropdownTags .chip p{
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;

}
.dropdownTags .chip {
    display: flex;
    padding: 4px 8px 4px 6px;
    background-color: #BCFFE7;
    border-radius: 27px;
    margin-right: 4px;
}
.dropdownTags .chip img{
    margin-right: 4px;
}
.addAnswer .answer{
    position: relative;
}
.roundClose{
    position: absolute;
    width: 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: white;
    opacity: 0;
    cursor: pointer;
    transition-duration: 300ms;
}
.dropdownInput .roundClose{
    right: -266px;
}
.dropdownInput.value .roundClose{
    right: -96px;
}
.dropdownInput input:focus ~ .roundClose{
    opacity: 1;

    width: 40px;
}
.question  .inputs.range .row:first-child{
    margin: 0;
}
.question  .inputs.range .row .questionDropdown:first-child{
    width: 213px;
}
.question .inputs.range .row .questionDropdown .type_text{
    width: 211px;
}
.question .inputs.range .type_text .row:first-child{
    justify-content: flex-start;
}
.question .inputs.range .row .questionDropdown .type_text p{
    width: max-content;
    margin-left: -20px;
}
.question .inputs.range .row .questionDropdown:last-child{
    width: 318px;
}
.question .inputs.range .row:first-child  .questionDropdown:last-child p{
    width: 298px;
}
.question .inputs.range .row:first-child  .questionDropdown:last-child .levelDropdownContent p{
    width: 100%;
}
.question .inputs.range .row .questionDropdown:last-child p{
    opacity: 1;
}
.answersLimiter{
    margin-top: 26px;
}
.answersLimiter p{
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    opacity: 0.5;
}
.answersLimiter .row{
    width: 100px;
}
.answersLimiter input{
    width: 100px;
    padding-top: 7px;
    padding-bottom: 12px;
    border: 0;
    border-bottom: 1px solid #D3DADE;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    outline: none;
}