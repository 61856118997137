*{
  padding: 0;
  margin: 0;
  font-family: Gilroy, sans-serif;
  color: #0F213F;
}
html, body, #root{
  height: 100%;
}
button, a{
  cursor: pointer;
  text-decoration: none;
}
textarea{
  resize: none;
}
.App {
  text-align: center;
  font-family: Gilroy, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.purpleFilled, .greyFilled{


  border: 0;
  padding: 16px 32px;
  font-family: 'Gilroy', sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.06em;
  text-align: left;

}
.purpleFilled{
  background-color: #5056C9;
  color: white;
}
.greyFilled{
  background-color:#5056C91F;
  color: #5056C9;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container{
  max-width: 1360px;
  margin: 0 auto;
}
.row{
  display: flex;
}
@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Regular.eot');
  src: local('Gilroy Regular'), local('public/fonts/Gilroy-Regular'),
  url('fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-Regular.woff2') format('woff2'),
  url('fonts/Gilroy-Regular.woff') format('woff'),
  url('fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-ExtraboldItalic.eot');
  src: local('Gilroy Extrabold Italic'), local('public/fonts/Gilroy-ExtraboldItalic'),
  url('fonts/Gilroy-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-ExtraboldItalic.woff2') format('woff2'),
  url('fonts/Gilroy-ExtraboldItalic.woff') format('woff'),
  url('fonts/Gilroy-ExtraboldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Bold.eot');
  src: local('Gilroy Bold'), local('public/fonts/Gilroy-Bold'),
  url('fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-Bold.woff2') format('woff2'),
  url('fonts/Gilroy-Bold.woff') format('woff'),
  url('fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Black.eot');
  src: local('Gilroy Black'), local('public/fonts/Gilroy-Black'),
  url('fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-Black.woff2') format('woff2'),
  url('fonts/Gilroy-Black.woff') format('woff'),
  url('fonts/Gilroy-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Light.eot');
  src: local('Gilroy Light'), local('public/fonts/Gilroy-Light'),
  url('fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-Light.woff2') format('woff2'),
  url('fonts/Gilroy-Light.woff') format('woff'),
  url('fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Semibold.eot');
  src: local('Gilroy Semibold'), local('public/fonts/Gilroy-Semibold'),
  url('fonts/Gilroy-Semibold.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-Semibold.woff2') format('woff2'),
  url('fonts/Gilroy-Semibold.woff') format('woff'),
  url('fonts/Gilroy-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Medium.eot');
  src: local('Gilroy Medium'), local('public/fonts/Gilroy-Medium'),
  url('fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-Medium.woff2') format('woff2'),
  url('fonts/Gilroy-Medium.woff') format('woff'),
  url('fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-MediumItalic.eot');
  src: local('Gilroy Medium Italic'), local('public/fonts/Gilroy-MediumItalic'),
  url('fonts/Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-MediumItalic.woff2') format('woff2'),
  url('fonts/Gilroy-MediumItalic.woff') format('woff'),
  url('fonts/Gilroy-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-BlackItalic.eot');
  src: local('Gilroy Black Italic'), local('public/fonts/Gilroy-BlackItalic'),
  url('fonts/Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-BlackItalic.woff2') format('woff2'),
  url('fonts/Gilroy-BlackItalic.woff') format('woff'),
  url('fonts/Gilroy-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-UltraLight.eot');
  src: local('Gilroy UltraLight'), local('public/fonts/Gilroy-UltraLight'),
  url('fonts/Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-UltraLight.woff2') format('woff2'),
  url('fonts/Gilroy-UltraLight.woff') format('woff'),
  url('fonts/Gilroy-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-RegularItalic.eot');
  src: local('Gilroy Regular Italic'), local('public/fonts/Gilroy-RegularItalic'),
  url('fonts/Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-RegularItalic.woff2') format('woff2'),
  url('fonts/Gilroy-RegularItalic.woff') format('woff'),
  url('fonts/Gilroy-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-SemiboldItalic.eot');
  src: local('Gilroy Semibold Italic'), local('public/fonts/Gilroy-SemiboldItalic'),
  url('fonts/Gilroy-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-SemiboldItalic.woff2') format('woff2'),
  url('fonts/Gilroy-SemiboldItalic.woff') format('woff'),
  url('fonts/Gilroy-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-HeavyItalic.eot');
  src: local('Gilroy Heavy Italic'), local('public/fonts/Gilroy-HeavyItalic'),
  url('fonts/Gilroy-HeavyItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-HeavyItalic.woff2') format('woff2'),
  url('fonts/Gilroy-HeavyItalic.woff') format('woff'),
  url('fonts/Gilroy-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Extrabold.eot');
  src: local('Gilroy Extrabold'), local('public/fonts/Gilroy-Extrabold'),
  url('fonts/Gilroy-Extrabold.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-Extrabold.woff2') format('woff2'),
  url('fonts/Gilroy-Extrabold.woff') format('woff'),
  url('fonts/Gilroy-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-BoldItalic.eot');
  src: local('Gilroy Bold Italic'), local('public/fonts/Gilroy-BoldItalic'),
  url('fonts/Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-BoldItalic.woff2') format('woff2'),
  url('fonts/Gilroy-BoldItalic.woff') format('woff'),
  url('fonts/Gilroy-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-UltraLightItalic.eot');
  src: local('Gilroy UltraLight Italic'), local('public/fonts/Gilroy-UltraLightItalic'),
  url('fonts/Gilroy-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-UltraLightItalic.woff2') format('woff2'),
  url('fonts/Gilroy-UltraLightItalic.woff') format('woff'),
  url('fonts/Gilroy-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-LightItalic.eot');
  src: local('Gilroy Light Italic'), local('public/fonts/Gilroy-LightItalic'),
  url('fonts/Gilroy-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-LightItalic.woff2') format('woff2'),
  url('fonts/Gilroy-LightItalic.woff') format('woff'),
  url('fonts/Gilroy-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Heavy.eot');
  src: local('Gilroy Heavy'), local('public/fonts/Gilroy-Heavy'),
  url('fonts/Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-Heavy.woff2') format('woff2'),
  url('fonts/Gilroy-Heavy.woff') format('woff'),
  url('fonts/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Thin.eot');
  src: local('Gilroy Thin'), local('public/fonts/Gilroy-Thin'),
  url('fonts/Gilroy-Thin.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-Thin.woff2') format('woff2'),
  url('fonts/Gilroy-Thin.woff') format('woff'),
  url('fonts/Gilroy-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-ThinItalic.eot');
  src: local('Gilroy Thin Italic'), local('public/fonts/Gilroy-ThinItalic'),
  url('fonts/Gilroy-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Gilroy-ThinItalic.woff2') format('woff2'),
  url('fonts/Gilroy-ThinItalic.woff') format('woff'),
  url('fonts/Gilroy-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@media (max-width: 1440px) {
    .container{
      padding-left: 40px;
      padding-right: 40px;
    }
}