.generalPage{
    height: 100%;
    min-height: 800px;
}
.generalPage .content{
    height: calc(100% - 88px);
}
.generalPage .content .container{
    max-width: 1230px;
    height: calc(100% - 271px)
}
.generalPage .banner{
    padding-top: 72px;
    position: relative;
}
.generalPage .banner .bannerImg{
    position: absolute;
    top: -16px;
    right: -105px
}
.generalPage .banner h1{
    font-family: 'Gilroy', sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 66px;
    letter-spacing: 0em;
    text-align: left;
    width: 600px;
}
.generalPage .banner .row{
    width: 389px;
    justify-content: space-between;
    margin-top: 40px;
}

.generalRecommendations{
    background-color: #EAEAF8;;
    height: 175px;
    padding: 48px 0px;
}
.generalRecommendations h2{
    font-family: 'Gilroy', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0.02em;
    text-align: left;
}
.generalRecommendations .row{
    margin-top: 24px;

    justify-content: space-between;
}
.generalRecommendations .recommendation{
    background-color: white;
    padding: 32px 24px;
}
.generalRecommendations .recommendation{
    display: flex;
    justify-content: space-between;
    width: 342px;
}
.generalRecommendations .recommendation p{
    width: 270px;
    font-family: 'Gilroy', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}
.generalRecommendations .recommendation{
    align-items: center;
    cursor: pointer;
}
.generalRecommendations .recommendation:hover{
    box-shadow: 4px 8px 16px 0px #9CABB424;
}
.generalRecommendations .recommendation .imgHolder{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    background-color:#5056C91F;
    border-radius: 50%;

}
.generalPage .content .container.min-height{
    min-height: 441px;
}