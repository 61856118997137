.header{
    width: 100%;
    height: 88px;
    box-shadow: 0px 4px 6px 0px #9CABB41F;

}
.header .container{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}
.header .roundLogo{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #5056C9;;
}
.header .headerButtons{
    display: flex;
    column-gap: 40px;
    align-items: center;
}
.header .headerButtons .inner{
    display: flex;
    column-gap: 40px;
    align-items: center;
}
.header .dropdownHeaderButton{
    position: relative;

}

.header .dropdownHeaderButton button{
    height: 88px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-align: left;
    background-color: transparent;
    color: #0F213F;
    border: 0;
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
}
.header .dropdownHeaderButton button.active{
    border-bottom: 4px solid #5056c9;
}
.header .dropdownHeaderButton:hover .dropContent{
    display: block;
    z-index: 10;
}
.header .dropdownHeaderButton:hover .dropContent.long{
    width: 300px;
    left: 0;
    right: auto;
}
.header .dropdownHeaderButton:hover button img{
    transform: rotate(180deg);
}
.header .dropdownHeaderButton button img{
    margin-left: 4px;
    transition-duration: 300ms;
}
.header .dropdownHeaderButton .dropContent{
    display: none;
    position: absolute;
    top: 67px;
    right: 0px;
    width: 240px;
    padding-top: 12px;
    box-shadow: 4px 8px 16px 0px #9CABB424;
}
.header .dropdownHeaderButton .dropContent .dropContentButton{
    background-color: white;
    text-align: left;
    padding: 8px 20px;
    color: #0F213FCC;
    transition-duration: 300ms;
    cursor: pointer;
}
.header .dropdownHeaderButton .dropContent .dropContentButton:hover{
    background-color: #F4F7F9;
}
.header .profileHeaderButton button{
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;
}
.header .profileHeaderButton button .circleHolder{
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid #5056C9;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header .profileHeaderButton button .circleHolder img{
    border-radius: 50%;
}
.header .profileHeaderButton button > img{
    margin-left: 12px;
}

.header .dropdownHeaderButton .recentTests{
    font-family: 'Gilroy', sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.04em;
    text-align: left;
    padding: 4px 20px 12px;
}
.header .dropdownHeaderButton .dropContentButton.recent{
    border-bottom: 1px solid #D3DADE;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;

}
.header .dropdownHeaderButton .dropContentButton.recent:nth-child(2){
    border-top: 1px solid #D3DADE;
}
.header .dropdownHeaderButton .dropContentButton.recent .tag{
    font-family: 'Gilroy', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    padding: 4px 8px;
    border-radius: 27px;

}
.header .dropdownHeaderButton .dropContentButton.recent .row{
    column-gap: 4px;
    margin-top: 8px;
    flex-wrap: wrap;
    row-gap: 10px;
}
.currentTest{
    display: flex;
    margin-left: 228px;
}
.currentTest span{
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(15, 33, 63, 0.4);
    margin-right: 4px;
}
.currentTest p{
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #0F213F;
    margin-right: 4px;
}
button.login{
    background-color: #5056c9;
    color: white;
    width: 114px;
    height: 48px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border: 0;
}
.profileButton{
    padding: 0 25px;
    line-height: 56px;
    border-radius: 12px;
    background: rgba(17, 101, 250, 0.1);
    margin-left: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #2330ba;
    transition: opacity 0.3s;
    width: max-content;
    border: 0;
}
.profileButton:hover {
    text-decoration: underline;
}