.testsPage{
    height: 100%;
}

.testsContent.container{
    max-width: 1230px;
    padding-top: 56px;
}
.testsContent > .row:first-child{
    justify-content: space-between;
}
.testsContent h1{
    font-family: 'Gilroy', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.02em;
    text-align: left;

}
.testsContent .isPublishedButtons{
    column-gap: 40px;
    margin-top: 32px;
}


.testsContent .isPublishedButtons button{
    display: flex;
    background-color: transparent;
    border: 0;
    align-items: center;
    padding-bottom: 14px;
    font-family: 'Gilroy', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-align: left;
}
.testsContent .isPublishedButtons button.active{
    border-bottom: 4px solid #5056C9;
}
.testsContent .isPublishedButtons button.active .counter{
    background-color: #5056C9;
    color: white;
}
.testsContent .isPublishedButtons .counter{
    background: #5056C91F;
    color: #5056C9;
    padding: 4px 5px 2px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    vertical-align: center;
    letter-spacing: 0em;
    text-align: center;
}
.testsPage .testsBlock{

    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
}
.testsPage .testCard{
    width: 350px;
    background-color: white;

    padding: 12px 16px 20px 24px;
    height: calc(100% - 30px);
}
.testsHolder{
    height: calc(100% - 117px);
}
.testsHolder .cardHolder{
    background-color: #F4F7F9;
    min-height: calc(100% - 225px);
    padding-bottom: 80px;

}
.testsHolder .cardHolder .container{
    height: calc(100% );
    max-width: 1230px;
}
.testsBlock{
    padding-top: 24px;
}
.testsBlock .testCard{
    cursor: pointer;

}
.testsBlock .testCard:hover{
    box-shadow: 4px 6px 12px rgba(156, 171, 180, 0.14);
}
.testsBlock .testCard .row:first-child{
    justify-content: space-between;
    align-items: center;
}
.testsBlock .testCard .row:first-child h3{
    width: 294px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}
.testsBlock .testCard .row:first-child .dotsHolder:hover{
    background-color: #F4F7F9;
}
.testsBlock .testCard .row:first-child .dotsHolder{
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.testsBlock .testCard .row:first-child .dotsHolder .dotsContent{
    position: absolute;
    top: 8px;
    right: 0;
    z-index: 2;
    width: 224px;
    height: 148px;
    background-color: white;
    box-shadow: 4px 10px 24px 0px #9CABB424;

}
.testsBlock .testCard .row:nth-child(2){
    margin-top: 4px;
    column-gap: 4px;
    flex-wrap: wrap;
    row-gap: 10px;
}
.testsBlock .testCard .row:nth-child(2) .tag{
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    padding: 3px 7px;
    background: transparent;
    border: 1px solid #D3DADE;
    border-radius: 27px;

}
.testsBlock .testCard:hover .row:nth-child(2) .tag{
    background-color: #BCFFE7;
    padding: 4px 8px;
    border: 0;
}
.testsBlock .testCard > p{
    margin-top: 38px;
    color: #122443;
    opacity: 0.4;
}
.cardHolder.no-tests{
    padding-top: 20px;
}
.addTest{
    border: 0.5px solid #D3DADE;
    width: 390px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 154px;
    transition-duration: 300ms;
}
.addTest p{
    color: #5056C9;
    text-transform: uppercase;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-align: center;
    margin-left: 8px;
}
.addTest .row{
    align-items: center;
}
.addTest .plusButton{

    background-color: #5056C91F;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition-duration: 300ms;


}

.addTest:hover{
    border-color: white;
    background-color: white;
    box-shadow: 4px 6px 12px 0px #9CABB424;

}
.addTest:hover .plusButton{
    background-color: #5056C9;
}
.addTest .plusButton img{
    transition-duration: 300ms;

}
.addTest:hover .plusButton img{
    filter: brightness(0) invert(1);
}
.testsBlock .testCard .dotsContent{
    display: none;
}
.testsBlock .testCard .dotsHolder:hover .dotsContent{
    display: block;
}
.testsBlock .testCard .dotsContent .row{
    justify-content: flex-start;
    padding: 14px 16px;
    margin: 0;
    align-items: center;
    column-gap: 12px;
}
.testsBlock .testCard .dotsContent .row:last-child p{
    color: #EB3349;
}
.testsBlock .testCard .dotsContent .row:hover{
    background-color: #F4F7F9;
}
.testsSearch{
    background-color: #F4F7F9;
    padding-top: 24px;
}
.testsSearch .container{
    max-width: 1230px;
    display: flex;
}
.testsSearch .searchHolder{
    width: 390px;
    background-color:#9CABB426;
    height: 36px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    margin-right: 32px;
}
.testsSearch .searchHolder img{
    margin-left: 12px;
    margin-right: 8px;
}
.testsSearch .searchHolder input{
    background-color: transparent;
    border: 0;
    border-radius: 0;
    height: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    width: 232px;
    margin-right: 16px;
    outline: none;
}
.testsSearch .searchHolder button{
    background-color: white;
    width: 100px;
    height: 32px;
    border-radius: 24px;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-align: left;
    color: #5056c9;
    text-transform: uppercase;
    padding: 8px 24px;
}
.testsSearch .hashtagsSearch .levelDropdown{
    background-color: #9CABB426;
    height: 36px;
    width: 149px;
    padding: 0;
}

.testsSearch .hashtagsSearch .levelDropdown > img:first-child{
    margin-left: 12px;
    margin-right: 8px;
}
.testsSearch .hashtagsSearch .levelDropdown > img:nth-child(3){
    margin-left: 10px;
    margin-right: 10px;
}
.testsSearch .hashtagsSearch .levelDropdown > p{
    color: #0F213F90;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-right: 0px;
    width: min-content;
}
.testsSearch .hashtagsSearch .levelDropdown:hover{
    background-color: #9CABB426;
    border-radius: 24px;
    border-bottom: 0;
    box-shadow: 4px 8px 16px 0px #9cabb424;
    height: 36px;
}
.testsSearch .hashtagsSearch .levelDropdown:hover .chipDrop{
    left: 0;
    right: inherit;
    top: 36px;
}
.testsSearch .hashtagsSearch .levelDropdown .chipDrop{
    width: 600px;
    column-gap: 4px;
    row-gap: 4px;
}
.testsSearch .hashtagsSearch .levelDropdown:hover .chipDrop{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}
.testsSearch .hashtagsSearch .levelDropdown .chipDrop .chip{
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #0F213F;
    padding: 4px 8px;
    background-color: #BCFFE7;
    border-radius: 27px;
    transition-duration: 300ms;

}
.testsSearch .hashtagsSearch .levelDropdown .chipDrop .chip:hover{
    background-color: #92f8dc;
}
.tagChosen{
    min-width: 149px;
    display: flex;
    background-color: #9CABB426;
    height: 36px;
    border-radius: 24px;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
}
.tagChosen img{
    height: 20px;
}
.tagChosen img:first-child{
    margin-right: 8px;
}
.tagChosen p{
    margin-right: auto;
}